<template>
  <div>
    <v-card
        class="mx-auto pt-16 px-8"
        align="center"
        elevation="0"
    >
      <h1 style="font-size: 27px"><span style="font-weight: 300">{{$t('prices.title')}}</span> {{$t('prices.title_bold')}}</h1>
      <p class="body-1 py-4">{{$t('prices.subtitle')}}</p>
      <v-btn
          href="/createevent"
          color="#FF4B5B"
          dark
          elevation="0"
          class="create-event-btn"
          rounded
          x-large
      >
        {{ $t('features.button') }}
      </v-btn>
    </v-card>

    <div class="mt-16 mx-4" align="center">
      <v-img
          max-width="350px"
          src="@/assets/prices.jpg"
          contain
          rounded
          class="rounded mx-8"
      >
      </v-img>

    </div>

  </div>

</template>

<script>
export default {
  name: "PricesHeaderMobile"
}
</script>

<style scoped>

</style>